import $ from 'jquery';

/**
 * Generates the HTML structure for a user dropdown item.
 * It will display the username along with their fullname(more context about the user)
 * @param {Object} user - The user object containing user details.
 */
export function usersDropDownItemTemplate(user) {
  const $container = $(
    `<div>
      <div>
        <div class='select2-result-repository__title'></div>
        <div class='select2-result-repository__description'></div>
      </div>
    </div>`,
  );
  $container.find('.select2-result-repository__title').text(user.name);
  $container
    .find('.select2-result-repository__description')
    .text(user.username);
  return $container;
}

/**
 * Generates the HTML structure for a business partner dropdown item.
 * It will display the partner name along with their partner category)
 * primary phone number and  primary email address
 * @param {Object} user - The partner object containing user details.
 */
export function businessPartnersDropDownItemTemplate(partner) {
  const $container = $(
    `<div>
      <div>
        <div class='select2-result__title'></div>
        <small class='d-block select2-result__description'></small>
        <small class='d-block select2-result__phone_number'></small>
        <small class='d-block select2-result__email_address'></small>
      </div>
    </div>`,
  );
  $container.find('.select2-result__title').text(partner.partner_name);
  $container
    .find('.select2-result__description')
    .text(partner.partner_category);
  $container
    .find('.select2-result__phone_number')
    .text(partner.primary_phone_number);
  $container
    .find('.select2-result__email_addres')
    .text(partner.primary_email_address);
  return $container;
}

/**
 * Generates the HTML structure for a business partner location dropdown item.
 * It will display the location address along with their location kind)
 * @param {Object} user - The user object containing user details.
 */
export function businessPartnersLocationDropDownItemTemplate(partnerLocation) {
  const $container = $(
    `<div>
      <div>
        <div class='select2-result__title'></div>
        <small class='d-block select2-result__department'></small>
        <small class='d-block select2-result__description'></small>

      </div>
    </div>`,
  );
  $container
    .find('.select2-result__title')
    .text(partnerLocation.location_address);
  $container
    .find('.select2-result__description')
    .text(partnerLocation.location_kind);
  $container
    .find('.select2-result__department')
    .text(partnerLocation.department || '');

  return $container;
}

/**
 * Custom display template for a selected partner location in a Select2 dropdown.
 * If the partner location has an ID (preselected options created manually) ,
 * it formats the location's address for display;
 * otherwise (location data fetched remotely), it falls back to displaying the default text.
 *
 * @param {Object} partnerLocation - The partner location object selected in the Select2 dropdown.
 * @returns {jQuery} - A jQuery object representing the HTML structure for the partner location's display.
 */
export function selectedPartnerLocationTemplateDisplayFunc(partnerLocation) {
  if (!partnerLocation) {
    return '';
  }

  if (!partnerLocation.id) {
    return partnerLocation.text;
  }
  // for remote data
  const $container = $(
    `<div>
      <div>
        <div class='select2-result__title'></div>
      </div>
    </div>`,
  );

  $container
    .find('.select2-result__title')
    .text(partnerLocation.location_address || partnerLocation.text);
  return $container;
}

/**
 * Custom display template for a selected partner in a Select2 dropdown.
 * If the partner has an ID(preselected options created manually), it formats the partner's name for display;
 * otherwise(those fetched remotely), it falls back to displaying the default text.
 *
 * @param {Object} partner - The partner object selected in the Select2 dropdown.
 * @returns {jQuery} - A jQuery object representing the HTML structure for the partner's display.
 */
export function selectedPartnerTemplateDisplayFunc(partner) {
  if (!partner) {
    return '';
  }
  if (!partner.id) {
    return partner.text;
  }
  // for remote data
  const $container = $(
    `<div>
      <div>
        <div class='select2-result__title'></div>
      </div>
    </div>`,
  );

  $container
    .find('.select2-result__title')
    .text(partner.partner_name || partner.text);
  return $container;
}

/**
 * Generates the HTML structure for the selected  user from the  dropdown.
 * It will display the username or fullname(more context about the user)
 * @param {Object} user - The user object containing user details.
 */
export function selectedUserTemplateDisplayFunc(user) {
  return user.name || user.username;
}

/**
 * Specifies the parameters sent along when requesting user data.
 * the term user for querying is "search"
 * Example: 127.0.0.1:8000/api/users/?search="John Doe".
 */
export function defaultRequestParamsFunc(params) {
  return { search: params.term || '' };
}

/**
 * Define how user response data is processed so that select2 understands.
 * Typical DRF response holds response  data in the results key
 * Along with it is the "count", "page"
  {
    "count": 2,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 1,
            "name": "Item 1",
            "description": "Description of Item 1",
            "created_at": "2024-09-02T10:00:00Z",
            "updated_at": "2024-09-02T10:00:00Z"
        },
    ]
  }

 */
export function defaultProcessResponseResultsFunc(data) {
  return { results: data.results || [] };
}
