/* istanbul ignore file */
/*global jQuery,Set,Map */

import { Controller } from '@hotwired/stimulus';
import 'jquery-sortablejs';
import {
  drawMap,
  drawPolygon,
  getDirectionsRenderer,
  getDirectionsService,
  setMapBounds,
  setOnMap,
} from '../utils/googleMapsUtils';

const pageAction = {
  createRoute: 'CREATE_ROUTE',
};

export default class extends Controller {
  static targets = [
    'routeSummary',
    'origin',
    'mapContainer',
    'destination',
    'waypoints',
    'originSummary',
    'waypointsSummary',
    'destinationSummary',
    'stopsList',
    'orderedWaypoints',
    'locationsFeedback',
  ];

  page = null;
  service = null;
  renderer = null;
  request = null;
  response = null;
  startReady = false;
  stopReady = false;
  waypointsReady = true;
  travelReady = true;
  stopsSet = null;
  stopsList = null;
  stopsMap = null;

  defaultLat = 0.05034781599503812;
  defaultLong = 37.645456670838506;

  async connect() {
    this.stopsSet = new Set();
    this.stopsList = new Array();
    this.stopsMap = new Map();
    this.page = this.element.dataset.page;
    this.request = {
      origin: null,
      destination: null,
      waypoints: [],
      travelMode: 'DRIVING',
      optimizeWaypoints: false,
    };

    switch (this.page) {
      case pageAction.createRoute:
        await processCreateRoute(this);
        break;
      default:
        console.error('Invalid Page Action!');
        break;
    }
  }

  setRequestOrigin(coords) {
    this.startReady = true;
    this.request.origin = { placeId: coords };
    this.drawRoute();
  }

  setRequestDestination(coords) {
    this.stopReady = true;
    this.request.destination = { placeId: coords };
    this.drawRoute();
  }

  setRequestWaypoints(coords) {
    this.waypointsReady = true;
    this.request.waypoints = coords;
    this.drawRoute();
  }

  setDirectionServices(service, renderer) {
    this.service = service;
    this.renderer = renderer;
  }

  async drawRoute() {
    const postResponse = () => {
      this.orderedWaypointsTarget.value = this.stopsList.join('|');
    };

    if (
      this.startReady &&
      this.stopReady &&
      this.travelReady &&
      this.waypointsReady
    ) {
      await displayRoute(
        this.request,
        this.service,
        this.renderer,
        postResponse,
      );
    }
  }
}

const processCreateRoute = async controller => {
  const map = await setupMap(controller);
  const { service, renderer } = await setupDirectionsService(map);
  controller.setDirectionServices(service, renderer);

  drawCurrentZone(controller, map);
  setSelectListeners(controller);
  setWaypointsListener(controller);
  setupSortable(controller);
};

const drawCurrentZone = async (controller, map) => {
  const boundary = JSON.parse(controller.element.dataset.currentCoords);
  if (boundary.length > 2) {
    const polygon = await drawPolygon(boundary, false, '#7da733');
    setOnMap(polygon, map);
    await setMapBounds(map, polygon);
    return polygon;
  }
};

const setupMap = async controller => {
  const center = { lat: controller.defaultLat, lng: controller.defaultLong };
  const map = await drawMap(controller.mapContainerTarget, center);
  return map;
};

const setupDirectionsService = async map => {
  const directionsService = await getDirectionsService();
  const service = new directionsService();
  const directionsRenderer = await getDirectionsRenderer();
  const renderer = new directionsRenderer({ map });
  return { service, renderer };
};

const displayRoute = async (request, service, renderer, callback) => {
  service
    .route(request)
    .then(response => {
      renderer.setDirections(response);
      callback(response);
    })
    .catch(e => {
      console.error('Could not display directions due to: ' + e);
    });
};

const setSelectListeners = async controller => {
  setOriginListener(controller, controller.originTarget, 'Origin');
  setOriginListener(controller, controller.destinationTarget, 'Destination');
};

const setOriginListener = async (controller, element, title) => {
  const parseOptionContext = () => {
    const selectedOption = element.options[element.selectedIndex];
    let context = selectedOption.dataset.place_id;
    return context;
  };

  const setupReadiness = () => {
    let coords = parseOptionContext();

    if (title == 'Origin') {
      controller.setRequestOrigin(coords);
    }
    if (title == 'Destination') {
      controller.setRequestDestination(coords);
    }
  };

  if (element.value) {
    setupReadiness();
    syncLocationDropdown(controller);
  }

  element.addEventListener('change', () => {
    setupReadiness();
    syncLocationDropdown(controller);
  });
};

const syncLocationDropdown = async controller => {
  const waypointsElement = controller.waypointsTarget;
  const startElement = controller.originTarget;
  const endElement = controller.destinationTarget;
  const startValue = startElement.options[startElement.selectedIndex].value;
  const endValue = endElement.options[endElement.selectedIndex].value;

  Array.from(waypointsElement.options).forEach(option => {
    const optionValue = option.value;
    if (optionValue === startValue || optionValue === endValue) {
      option.disabled = true;
      option.selected = false;
      waypointsElement.value = '';
      syncStopsList(controller, option);
    } else {
      option.disabled = false;
    }
    refreshWaypointsOptions();
  });
};

const refreshWaypointsOptions = async () => {
  jQuery('#id_locations').selectpicker('refresh');
};

const setWaypointsReadiness = controller => {
  const options = Array.from(controller.stopsSet);
  const context = options.map(option => {
    return {
      location: { placeId: option },
      stopover: true,
    };
  });
  controller.setRequestWaypoints(context);
};

const setWaypointsListener = async controller => {
  const waypointsElement = controller.waypointsTarget;

  waypointsElement.addEventListener('change', () => {
    const option = waypointsElement.options[waypointsElement.selectedIndex];

    if (!controller.stopsMap.has(option.value)) {
      controller.stopsMap.set(option.value, option);
      controller.stopsList.push(option.value);
      controller.stopsSet.add(option.dataset.place_id);
      option.disabled = true;
      option.selected = false;
      displaySuccess(controller);
      refreshWaypointsOptions();
    }

    populateStopsList(controller);
  });
};

const setupSortable = controller => {
  try {
    jQuery('#stops-list').sortable('destroy');
  } catch {
    null;
  }

  jQuery('#stops-list').sortable({
    stop: function (event, ui) {
      event, ui;
      setTimeout(() => {
        controller.stopsList = new Array();
        controller.stopsSet = new Set();

        for (let i = 0; i < controller.stopsListTarget.children.length; i++) {
          let li = controller.stopsListTarget.children[i];
          let identity = li.getAttribute('data-identity');
          let place_id = li.getAttribute('data-place_id');
          if (identity) {
            controller.stopsList.push(identity);
            controller.stopsSet.add(place_id);
          }
        }

        setWaypointsReadiness(controller);
      }, 1000);
    },
  });
};

const syncStopsList = async (controller, option) => {
  controller.stopsMap.delete(option.value, option);
  controller.stopsList = controller.stopsList.filter(
    value => value !== option.value,
  );
  controller.stopsSet.delete(option.dataset.place_id);
  populateStopsList(controller);
};

const populateStopsList = controller => {
  controller.stopsListTarget.replaceChildren();

  controller.stopsList.forEach(key => {
    let option = controller.stopsMap.get(key);

    let container = containerDiv();
    let handle = handleDiv();
    let content = contentDiv(option);
    let close = closeDiv();
    let button = createButton(option);

    button.addEventListener('click', () => {
      syncStopsList(controller, option);
      option.disabled = false;
      refreshWaypointsOptions();
    });

    close.appendChild(button);

    container.appendChild(handle);
    container.appendChild(content);
    container.appendChild(close);

    let li = document.createElement('li');
    li.setAttribute('data-identity', key);
    li.setAttribute('data-place_id', option.dataset.place_id);
    li.classList.add('list-group-item', 'my-1', 'border', 'rounded');
    li.appendChild(container);
    li.style.fontSize = '12px';

    controller.stopsListTarget.append(li);
  });

  setWaypointsReadiness(controller);
};

const containerDiv = () => {
  let element = document.createElement('div');
  element.classList.add('d-flex', 'align-items-center');
  return element;
};

const handleDiv = () => {
  let element = document.createElement('div');
  element.classList.add('px-2');
  let iconElement = document.createElement('i');
  iconElement.classList.add('fas', 'fa-bars');
  element.appendChild(iconElement);
  return element;
};

const contentDiv = option => {
  let element = document.createElement('div');
  element.classList.add('px-2', 'flex-grow-1');
  element.innerHTML = option.dataset.content;
  return element;
};

const closeDiv = () => {
  let element = document.createElement('div');
  element.classList.add('px-2', 'ml-auto');
  return element;
};

const createButton = option => {
  let button = document.createElement('button');
  button.type = 'button';
  button.classList.add('close');
  button.innerHTML = `<span aria-hidden="true">&times;</span>`;
  button.setAttribute('aria-label', 'Close');
  button.setAttribute('data-value', option.value);
  button.setAttribute('data-identity', option.dataset.place_id);
  return button;
};

const displaySuccess = async controller => {
  controller.locationsFeedbackTarget.classList.add(
    'text-success',
    'font-weight-bolder',
  );
  controller.locationsFeedbackTarget.textContent =
    'Success! Stop has been added below';
  setTimeout(() => {
    controller.locationsFeedbackTarget.classList.remove('text-success');
    controller.locationsFeedbackTarget.textContent = '';
  }, 5000);
};
