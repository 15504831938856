import {
  usersDropDownItemTemplate,
  defaultRequestParamsFunc,
  defaultProcessResponseResultsFunc,
  selectedUserTemplateDisplayFunc,
  businessPartnersDropDownItemTemplate,
  selectedPartnerTemplateDisplayFunc,
  businessPartnersLocationDropDownItemTemplate,
  selectedPartnerLocationTemplateDisplayFunc,
} from '../helpers/selectDropDownConfigHelpers';

export const configurations = {
  userSearch: {
    url: '/api/users/',
    theme: 'bootstrap-5',
    closeOnSelect: true,
    data: defaultRequestParamsFunc,
    processResults: defaultProcessResponseResultsFunc,
    templateResult: usersDropDownItemTemplate,
    templateSelection: selectedUserTemplateDisplayFunc,
    placeholder: 'Search for users',
    minimumInputLength: 2,
  },
  businessPartnersSearch: {
    url: '/api/business_partners/',
    theme: 'bootstrap-5',
    closeOnSelect: true,
    data: defaultRequestParamsFunc,
    processResults: defaultProcessResponseResultsFunc,
    templateResult: businessPartnersDropDownItemTemplate,
    templateSelection: selectedPartnerTemplateDisplayFunc,
    placeholder: 'Search for a business partner',
    minimumInputLength: 2,
  },
  businessPartnersLocationsSearch: {
    url: '/api/business_partners_location_addresses/',
    theme: 'bootstrap-5',
    closeOnSelect: true,
    data: defaultRequestParamsFunc,
    processResults: defaultProcessResponseResultsFunc,
    templateResult: businessPartnersLocationDropDownItemTemplate,
    templateSelection: selectedPartnerLocationTemplateDisplayFunc,
    placeholder: 'Search for a business partner location',
    minimumInputLength: 2,
  },
  // configurations for othe objects(business partners, locations) can be added here
};

export default configurations;
