export function removeWheelsFromNumberInputs() {
  // Remove the WHEEL that comes with number inputs
  const numberInputs = document.querySelectorAll('input[type=number]');
  // Disable the 'wheel' event for all number input elements
  numberInputs.forEach(function (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault(); // Prevent the default scroll behavior
    });
  });
}

export function navigateTo(url, timeout = 1000) {
  setTimeout(function () {
    window.location.replace(url);
  }, timeout);
}

export function delayCall(timeout = 1000) {
  setTimeout(function () {}, timeout);
}
