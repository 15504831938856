import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tabList', 'dropdownMenu', 'dropdownList'];

  tabList = '';
  tabItems = '';
  dropdownMenu = '';
  dropdownList = '';
  resizeObserver = '';
  availableWidth = '';
  totalWidth = '';

  connect() {
    this.init();
    this.transformTabs();
    this.attachEventListeners();
    this.listenToWindowResize();
  }

  init() {
    this.element.dataset.stimulusStatus = 'connected';
    this.tabList = this.tabListTarget;
    this.dropdownMenu = this.dropdownMenuTarget;
    this.dropdownList = this.dropdownListTarget;
    this.tabItems = this.tabList.querySelectorAll('.nav-item');
    this.cloneTabItems();
  }

  cloneTabItems() {
    this.tabItems.forEach(element => {
      let clone = element.cloneNode(true);
      clone.classList.remove('nav-item');
      this.dropdownList.appendChild(clone);
    });
  }

  transformTabs() {
    this.availableWidth =
      this.element.clientWidth - 2 * this.dropdownMenu.clientWidth;
    this.totalWidth = 0;

    this.performTransformTabs();
  }

  performTransformTabs() {
    this.tabItems.forEach((item, index) => {
      let clone = this.dropdownList.children[index];

      // Reset visibility for all tabs
      item.classList.remove('d-none');
      item.classList.add('nav-item');
      // Reset visibility for all dropdown items
      this.dropdownMenu.classList.add('d-none');
      clone.classList.add('d-none');

      this.totalWidth += item.clientWidth;

      if (this.totalWidth > this.availableWidth) {
        // Hide from tab list
        item.classList.remove('nav-item');
        item.classList.add('d-none');
        // Show in dropdown
        this.dropdownMenu.classList.remove('d-none');
        clone.classList.remove('d-none');
      }
    });
  }

  attachEventListeners() {
    // Attach event listeners to both tab list and dropdown items
    this.tabList.querySelectorAll('.nav-link').forEach(link => {
      link.addEventListener('click', this.handleTabClick.bind(this));
    });

    this.dropdownList.querySelectorAll('.nav-link').forEach(link => {
      link.addEventListener('click', this.handleDropdownClick.bind(this));
    });
  }

  handleTabClick(event) {
    const clickedTabId = event.target.id;

    // Remove 'active' from all tabs and dropdown items
    this.clearActiveState();
    // Add 'active' to the clicked tab in both tab list and dropdown list
    this.activateTab(clickedTabId);
  }

  handleDropdownClick(event) {
    const clickedTabId = event.target.id;

    // Remove 'active' from all tabs and dropdown items
    this.clearActiveState();
    // Add 'active' to the clicked tab in both tab list and dropdown list
    this.activateTab(clickedTabId);
  }

  clearActiveState() {
    // Clear 'active' class from both the main tab list and dropdown list
    this.tabList
      .querySelectorAll('.nav-link')
      .forEach(link => link.classList.remove('active'));
    this.dropdownList
      .querySelectorAll('.nav-link')
      .forEach(link => link.classList.remove('active'));
  }

  activateTab(tabId) {
    // Activate the corresponding tab in the main tab list and the dropdown list
    const tabLink = this.tabList.querySelector(`#${tabId}`);
    const dropdownLink = this.dropdownList.querySelector(`#${tabId}`);

    tabLink?.classList.add('active');
    dropdownLink?.classList.add('active');
  }

  listenToWindowResize() {
    this.resizeObserver = new ResizeObserver(entries => {
      requestAnimationFrame(() => {
        /* istanbul ignore else */
        if (entries?.length) this.transformTabs();
      });
    });
    this.resizeObserver.observe(this.element);
  }

  disconnect() {
    this.resizeObserver?.disconnect();
  }
}
