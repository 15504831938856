import { Controller } from '@hotwired/stimulus';
import { navigateTo } from '../utils/generalUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = [
    'name',
    'contactperson',
    'phone',
    'email',
    'branch',
    'partner',
    'formfeedback',
    'namefeedback',
    'contactpersonfeedback',
    'phonefeedback',
    'emailfeedback',
    'loader',
  ];

  connect() {
    this.element.dataset.stimulusStatus = 'connected';
    this.element.addEventListener('submit', e => {
      e.preventDefault();

      if (this.isValid()) {
        this.loaderTarget.classList.add(
          'loader',
          'loader-default',
          'is-active',
        );

        const data = new FormData();
        data.append('partner', this.partnerTarget.dataset.partnerId);
        data.append('name', this.nameTarget.value);
        data.append('phone_number', this.phoneTarget.value);
        data.append('email_address', this.emailTarget.value);
        data.append('contact_person', this.contactpersonTarget.value);
        data.append('parent', this.branchTarget.value);

        this.postData(data).then(response => {
          if (response) {
            if (response.status == 201) {
              this.displaySuccess(response.data);
            } else {
              this.handleFormErrors(response.data);
            }
          }
        });
      }
    });
  }

  async postData(data) {
    try {
      const url = '/partners/add_corporate_branch';
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
          'X-CSRFToken': this.element.dataset.csrf,
        },
      });

      const response_data = await response.json();
      const status = response.status;

      return { data: response_data, status: status };
    } catch (error) {
      console.error(error);
    }
  }

  handleFormErrors(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');
    if (data.phone_number) {
      this.displayGlobalError(data.phone_number);
    }

    if (data.partner) {
      this.displayGlobalError(data.partner);
    }

    if (data.name) {
      this.displayError(this.nameTarget, this.namefeedbackTarget, data.name);
    }
  }

  displayError(element, elementTarget, data) {
    element.classList.add('is-invalid');
    elementTarget.classList.add('invalid-feedback');
    elementTarget.innerHTML = data;
  }

  displayGlobalError(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-success');
    this.formfeedbackTarget.classList.add('alert-danger');
    this.formfeedbackTarget.innerHTML = data;
  }

  displaySuccess(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-danger');
    this.formfeedbackTarget.classList.add('alert-success');
    this.formfeedbackTarget.innerHTML =
      `<span class="d-block">Branch ${data.name} has been created for the portal.</span>` +
      `<span class="d-block">Refreshing automatically...</span>`;

    let timeout = 1000; // 1 seconds
    navigateTo(data.url, timeout);
  }

  isValid() {
    if (this.phoneTarget.classList.contains('is-invalid')) {
      return false;
    }
    return true;
  }
}
