import { Controller } from '@hotwired/stimulus';
import { autoComplete } from '../utils/googleMapsUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = ['address', 'latitude', 'longitude', 'placeId'];

  connect() {
    this.setupAutoComplete();
  }

  async setupAutoComplete() {
    const autocomplete = await autoComplete(this.addressTarget);
    autocomplete.addListener('place_changed', () => {
      const placeDetails = autocomplete.getPlace();
      this.fillInCoordinates(placeDetails);
    });
  }

  fillInCoordinates(placeDetails) {
    this.latitudeTarget.value = placeDetails.geometry.location.lat();
    this.longitudeTarget.value = placeDetails.geometry.location.lng();
    this.placeIdTarget.value = placeDetails.place_id;
  }
}
