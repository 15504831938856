export const ElementIds = {
  originId: 'originId',
  destinationId: 'destinationId',
  senderId: 'senderId',
  fetchPriceBtnId: 'fetch-price-btn',
  classOfServiceElementId: 'id_class_of_service',
  classOfServiceInputValueErrMsgId: 'id_classofserviceError',
  pricingSpinnerOverlayId: 'pricing-spinner-overlay',
  errorMessageTextId: 'error-message-text',
  errorMessageContainerId: 'error-message-container',
  newPriceInputId: 'new-parcel-price',
  generateCollectionDeliveryBtnId: 'save-and-generate-delivery',
  saveAndEditPriceBtnId: 'save-and-edit',
  collectionId: 'collectionId',
  alertContainerWrapperId: 'deliveryAlertContainer',
  alertPrimaryTextContainerId: 'alertPrimaryText',
  alertSecondaryTextContainerId: 'alertSecondaryText',
  priceDisplayContainerId: 'priceDisplayContainer',
  orgCanEditPriceId: 'org-edit-price',
  defaultSKUTaxRateId: 'default-sku-tax-rate',
  parcel_size_id: 'id_parcel_size',
  lengthElementId: 'id_length',
  lengthInputValueErrMsgId: 'id_lengthError',
  widthElementId: 'id_width',
  widthInputValueErrMsgId: 'id_widthError',
  heightElementId: 'id_height',
  heightInputValueErrMsgId: 'id_heightError',
  quantityElementId: 'id_quantity',
  quantityInputValueErrMsgId: 'id_quantityError',
  taxTextId: 'taxText',
  totalTextId: 'totalText',
  subtotalTextId: 'subtotalText',
  newPriceInputValueErrMsgId: 'new-parcel-priceError',
  priceDisplayWrapperId: 'priceDisplayWrapper',
  weightInputId: 'id_weight',
  weightInputValueErrMsgId: 'id_weightError',
  ratingTableTextId: 'ratingTableTextId',
  rateCardTextId: 'rateCardTextId',
  rateCardAndTableTextParentId: 'rateCardAndTableTextParentId',
  skuTaxContainerId: 'skuTaxContainerId',
  selects: ['id_parcel_size', 'id_class_of_service'],
};

export function setupVariables(ElementIdsObj) {
  const ElementIds = ElementIdsObj;
  const constants = {
    MINIMUM_VALUE: 0,
    ZERO_RATED_VAT: parseFloat(0),
    FETCH_PRICE_URL: `/pricing/sku_pricing/`,
    classOfServiceElement: document.querySelector(
      `#${ElementIds.classOfServiceElementId}`,
    ),
    classOfServiceInputValueErrMsg: document.querySelector(
      `#${ElementIds.classOfServiceInputValueErrMsgId}`,
    ),
    // prettier-ignore
    originIdValue: document.querySelector(`#${ElementIds.originId}`).innerText.trim(),
    heightElement: document.querySelector(`#${ElementIds.heightElementId}`),
    heightInputValueErrMsg: document.querySelector(
      `#${ElementIds.heightInputValueErrMsgId}`,
    ),
    parcelSizeInput: document.querySelector(`#${ElementIds.parcel_size_id}`),
    widthInput: document.querySelector(`#${ElementIds.widthElementId}`),
    widthInputValueErrMsg: document.querySelector(
      `#${ElementIds.widthInputValueErrMsgId}`,
    ),
    lengthInput: document.querySelector(`#${ElementIds.lengthElementId}`),
    lengthInputValueErrMsg: document.querySelector(
      `#${ElementIds.lengthInputValueErrMsgId}`,
    ),
    weightInput: document.querySelector(`#${ElementIds.weightInputId}`),
    weightInputValueErrMsg: document.querySelector(
      `#${ElementIds.weightInputValueErrMsgId}`,
    ),
    destinationIdValue: document
      .querySelector(`#${ElementIds.destinationId}`)
      .innerText.trim(),
    senderIdValue: document
      .querySelector(`#${ElementIds.senderId}`)
      .innerText.trim(),
    fetchPriceBtn: document.querySelector(`#${ElementIds.fetchPriceBtnId}`),
    pricingSpinnerOverlay: document.querySelector(
      `#${ElementIds.pricingSpinnerOverlayId}`,
    ),
    csrf_token: document.querySelector('input[name=csrfmiddlewaretoken]'),
    errorMessageText: document.querySelector(
      `#${ElementIds.errorMessageTextId}`,
    ),
    errorMessageContainer: document.querySelector(
      `#${ElementIds.errorMessageContainerId}`,
    ),
    newPriceInput: document.querySelector(`#${ElementIds.newPriceInputId}`),
    generateCollectionDeliveryBtn: document.querySelector(
      `#${ElementIds.generateCollectionDeliveryBtnId}`,
    ),
    saveAndEditPriceBtn: document.querySelector(
      `#${ElementIds.saveAndEditPriceBtnId}`,
    ),
    newPriceInputValueErrMsg: document.querySelector(
      `#${ElementIds.newPriceInputValueErrMsgId}`,
    ),
    collectionIdValue: document
      .querySelector(`#${ElementIds.collectionId}`)
      .innerText.trim(),
    quantityValue: Number(
      document.querySelector(`#${ElementIds.quantityElementId}`).value,
    ),
    quantity: document.querySelector(`#${ElementIds.quantityElementId}`),
    quantityInputValueErrMsg: document.querySelector(
      `#${ElementIds.quantityInputValueErrMsgId}`,
    ),

    alertContainerWrapper: document.querySelector(
      `#${ElementIds.alertContainerWrapperId}`,
    ),
    alertPrimaryTextContainer: document.querySelector(
      `#${ElementIds.alertPrimaryTextContainerId}`,
    ),
    alertSecondaryTextContainer: document.querySelector(
      `#${ElementIds.alertSecondaryTextContainerId}`,
    ),
    priceDisplayContainer: document.querySelector(
      `#${ElementIds.priceDisplayContainerId}`,
    ),
    orgCanEditPriceValue: document
      .querySelector(`#${ElementIds.orgCanEditPriceId}`)
      .innerHTML.trim(),
    defaultSKUTaxRateValue: document
      .querySelector(`#${ElementIds.defaultSKUTaxRateId}`)
      .innerHTML.trim(),
    priceDisplayWrapper: document.querySelector(
      `#${ElementIds.priceDisplayWrapperId}`,
    ),
    skuTaxContainer: document.querySelector(`#${ElementIds.skuTaxContainerId}`),
    taxText: document.querySelector(`#${ElementIds.taxTextId}`),
    totalText: document.querySelector(`#${ElementIds.totalTextId}`),
    subtotalText: document.querySelector(`#${ElementIds.subtotalTextId}`),
    rateCardText: document.querySelector(`#${ElementIds.rateCardTextId}`),
    ratingTableText: document.querySelector(`#${ElementIds.ratingTableTextId}`),
    rateCardAndTableTextParent: document.querySelector(
      `#${ElementIds.rateCardAndTableTextParentId}`,
    ),
    BUSINESS_PARTNER_PAYMENT_TERM_URL: `/partners/api/partner/payment_term`,
  };

  return constants;
}
