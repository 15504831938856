import { Controller } from '@hotwired/stimulus';
import { navigateTo } from '../utils/generalUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = [
    'address',
    'latitude',
    'longitude',
    'kind',
    'description',
    'primary',
    'formfeedback',
    'loader',
    'partner',
  ];

  connect() {
    this.element.dataset.stimulusStatus = 'connected';
    this.element.addEventListener('submit', e => {
      e.preventDefault();

      if (this.isValid()) {
        this.loaderTarget.classList.add(
          'loader',
          'loader-default',
          'is-active',
        );

        const data = new FormData();
        data.append('partner_id', this.partnerTarget.dataset.partnerId);
        data.append('kind', this.kindTarget.value);
        data.append('address', this.addressTarget.value);
        data.append('description', this.descriptionTarget.value);
        data.append('latitude', this.latitudeTarget.value);
        data.append('longitude', this.longitudeTarget.value);
        data.append('make_primary', this.primaryTarget.value);

        this.postData(data).then(response => {
          if (response) {
            if (response.status == 201) {
              this.displaySuccess(response.data);
            } else {
              this.handleFormErrors(response.data);
            }
          }
        });
      }
    });
  }

  async postData(data) {
    try {
      const url = '/partners/add_location_address';
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
          'X-CSRFToken': this.element.dataset.csrf,
        },
      });

      const response_data = await response.json();
      const status = response.status;

      return { data: response_data, status: status };
    } catch (error) {
      console.error(error);
    }
  }

  displaySuccess(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-danger');
    this.formfeedbackTarget.classList.add('alert-success');
    this.formfeedbackTarget.innerHTML =
      `<span class="d-block">Location ${data.address} has been created.</span>` +
      `<span class="d-block">Refreshing automatically...</span>`;
    let timeout = 1000; // 1 seconds
    navigateTo(data.url, timeout);
  }

  handleFormErrors(data) {
    this.displayGlobalError(data.address);
  }

  displayGlobalError(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-success');
    this.formfeedbackTarget.classList.add('alert-danger');
    this.formfeedbackTarget.innerHTML = data;
  }

  isValid() {
    return !!this.latitudeTarget.value && !!this.longitudeTarget.value;
  }
}
