import { Controller } from '@hotwired/stimulus';
export default class editLineItemPrice extends Controller {
  static targets = [
    'newPriceInput',
    'fetchTaxBtn',
    'confirmBtn',
    'parcelIdElement',
    'previousURLElement',
    'parcelTaxAmountElement',
    'parcelUnitPriceElement',
    'newParcelTaxAmountElement',
    'newParcelTotalPriceElement',
    'newParcelUnitPriceElement',
    'newParcelUnitPriceAndTaxContainer',
    'parcelTotalPriceElement',
    'parcelQuantityElement',
    'parcelSenderElement',
    'parcelRecipientElement',
    'parcelOriginElement',
    'parcelDestinationElement',
    'parcelDetailSpinnerOverlay',
    'newPriceAndTaxSpinnerOverlay',
    'cancelPriceEditBtn',
    'taxAmountTextElement',
    'unitPriceTextElement',
    'totalPriceTextElement',
  ];

  prepareHTMLElements() {
    let htmlElements = {
      newPriceInputElement: this.newPriceInputTarget,
      fetchTaxBtn: this.fetchTaxBtnTarget,
      confirmBtn: this.confirmBtnTarget,
      parcelIdElement: this.parcelIdElementTarget,
      previousURLElement: this.previousURLElementTarget,
      csrfTokenInput: document.querySelector('input[name=csrfmiddlewaretoken]'),
      parcelTaxAmountElement: this.parcelTaxAmountElementTarget,
      parcelUnitPriceElement: this.parcelUnitPriceElementTarget,
      newParcelTaxAmountElement: this.newParcelTaxAmountElementTarget,
      newParcelTotalPriceElement: this.newParcelTotalPriceElementTarget,
      newParcelUnitPriceElement: this.newParcelUnitPriceElementTarget,
      newParcelUnitPriceAndTaxContainer:
        this.newParcelUnitPriceAndTaxContainerTarget,
      parcelTotalPriceElement: this.parcelTotalPriceElementTarget,
      parcelQuantityElement: this.parcelQuantityElementTarget,
      parcelSenderElement: this.parcelSenderElementTarget,
      parcelRecipientElement: this.parcelRecipientElementTarget,
      parcelOriginElement: this.parcelOriginElementTarget,
      parcelDestinationElement: this.parcelDestinationElementTarget,
      parcelDetailSpinnerOverlay: this.parcelDetailSpinnerOverlayTarget,
      newPriceAndTaxSpinnerOverlay: this.newPriceAndTaxSpinnerOverlayTarget,
      cancelPriceEditBtn: this.cancelPriceEditBtnTarget,
      taxAmountTextElement: this.taxAmountTextElementTarget,
      unitPriceTextElement: this.unitPriceTextElementTarget,
      totalPriceTextElement: this.totalPriceTextElementTarget,
    };
    return htmlElements;
  }

  connect() {
    this.prepareHTMLElements();
  }
}
