import { Controller } from '@hotwired/stimulus';
import { navigateTo } from '../utils/generalUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = [
    'contacttype',
    'contactvalue',
    'contactkind',
    'makeprimary',
    'formfeedback',
    'loader',
    'partner',
    'contactfeedback',
  ];

  addBorderToDropdowns() {
    [this.contacttypeTarget, this.contactkindTarget].forEach(el => {
      el.classList.add('border');
    });
  }

  connect() {
    this.element.dataset.stimulusStatus = 'connected';
    this.addBorderToDropdowns();
    this.element.addEventListener('submit', e => {
      e.preventDefault();

      this.loaderTarget.classList.add('loader', 'loader-default', 'is-active');

      const data = new FormData();
      data.append('partner_id', this.partnerTarget.dataset.partnerId);
      data.append('contact_type', this.contacttypeTarget.value);
      data.append('value', this.contactvalueTarget.value);
      data.append('kind', this.contactkindTarget.value);
      data.append('make_primary', this.makeprimaryTarget.checked);

      this.postData(data).then(response => {
        if (response) {
          if (response.status == 201) {
            this.displaySuccess(response.data);
          } else {
            this.handleFormErrors(response.data);
          }
        }
      });
    });
  }

  async postData(data) {
    try {
      const url = '/partners/add_contact_address';
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
          'X-CSRFToken': this.element.dataset.csrf,
        },
      });

      const response_data = await response.json();
      const status = response.status;

      return { data: response_data, status: status };
    } catch (error) {
      console.error(error);
    }
  }

  displaySuccess(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-danger');
    this.formfeedbackTarget.classList.add('alert-success');
    this.formfeedbackTarget.innerHTML =
      `<span class="d-block">Contact ${data.value} has been created.</span>` +
      `<span class="d-block">Refreshing automatically...</span>`;
    let timeout = 1000; // 1 seconds
    navigateTo(data.url, timeout);
  }

  handleFormErrors(data) {
    this.displayError(data.value);
  }

  displayError(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.contactvalueTarget.classList.add('is-invalid');
    this.contactfeedbackTarget.classList.add('invalid-feedback');
    this.contactfeedbackTarget.innerHTML = data;
  }
}
