import { Controller } from '@hotwired/stimulus';
import { navigateTo } from '../utils/generalUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = [
    'contactperson',
    'primaryphone',
    'secondaryphone',
    'primaryemail',
    'secondaryemail',
    'formfeedback',
    'loader',
    'partner',
    'designation',
    'department',
    'makeprimary',
  ];

  connect() {
    this.element.dataset.stimulusStatus = 'connected';
    this.element.addEventListener('submit', e => {
      e.preventDefault();

      if (this.isValid()) {
        this.loaderTarget.classList.add(
          'loader',
          'loader-default',
          'is-active',
        );

        const data = new FormData();
        data.append('partner_id', this.partnerTarget.dataset.partnerId);
        data.append('name', this.contactpersonTarget.value);
        data.append('phone', this.primaryphoneTarget.value);
        data.append('secondary_phone', this.secondaryphoneTarget.value);
        data.append('email', this.primaryemailTarget.value);
        data.append('secondary_email', this.secondaryemailTarget.value);
        data.append('designation', this.designationTarget.value);
        data.append('department', this.departmentTarget.value);
        data.append('make_primary', this.makeprimaryTarget.checked);

        this.postData(data).then(response => {
          if (response) {
            if (response.status == 201) {
              this.displaySuccess(response.data);
            } else {
              this.handleFormErrors(response.data);
            }
          }
        });
      }
    });
  }

  async postData(data) {
    try {
      const url = '/partners/add_contact_person';
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
          'X-CSRFToken': this.element.dataset.csrf,
        },
      });

      const response_data = await response.json();
      const status = response.status;

      return { data: response_data, status: status };
    } catch (error) {
      console.error(error);
    }
  }

  displaySuccess(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-danger');
    this.formfeedbackTarget.classList.add('alert-success');
    this.formfeedbackTarget.innerHTML =
      `<span class="d-block">Contact person '${data.name}' has been created.</span>` +
      `<span class="d-block">Refreshing automatically...</span>`;
    let timeout = 1000; // 1 seconds
    navigateTo(data.url, timeout);
  }

  handleFormErrors(data) {
    this.displayGlobalError(data);
  }

  displayGlobalError(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-success');
    this.formfeedbackTarget.classList.add('alert-danger');
    this.formfeedbackTarget.innerHTML = JSON.stringify(data);
  }

  isValid() {
    let valid = true;
    if (this.primaryphoneTarget.classList.contains('is-invalid')) {
      valid = false;
    }
    if (this.secondaryphoneTarget.classList.contains('is-invalid')) {
      valid = false;
    }
    return valid;
  }
}
