import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import 'select2';
import configurations from '../constants/selectDropDownConfigs';
import { setInitialValue } from './createCollectionForm_controller';
export default class EnableSelectTwo extends Controller {
  connect() {
    const identifier = this.element.dataset.identifier;
    const config = configurations[identifier];

    if (config) {
      if (!config.templateSelection || !config.templateResult) {
        console.error(
          `Configuration for identifier: ${identifier} must include both templateSelection and templateResult functions.`,
        );
        return;
      }
      const selectedData = this.element.getAttribute('selected-data');
      const selectedName = this.element.getAttribute('selected-name');
      if (!selectedData || !selectedName) {
        this.initializeSelect(config);
      } else {
        setInitialValue(this.element, config);
      }
    } else {
      console.error(`Configuration not found for identifier: ${identifier}`);
    }
  }

  initializeSelect(config) {
    $(this.element).select2({
      theme: config.theme || 'bootstrap-5',
      closeOnSelect: config.closeOnSelect || false,
      ajax: {
        url: config.url,
        delay: config.delay || 250,
        dataType: 'json',
        data: config.data
          ? config.data
          : function (params) {
              return { search: params.term };
            },
        processResults: config.processResults
          ? config.processResults
          : function (data) {
              return { results: data.results };
            },
        cache: typeof config.cache !== 'undefined' ? config.cache : true,
      },
      templateSelection: config.templateSelection,
      templateResult: config.templateResult,
      placeholder: config.placeholder || 'Searching',
      minimumInputLength: config.minimumInputLength || 1,
    });
  }
}
