import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

import 'bootstrap-fileinput/js/plugins/buffer';
import 'bootstrap-fileinput/js/plugins/filetype';
import 'bootstrap-fileinput/js/plugins/piexif';
import 'bootstrap-fileinput/js/plugins/sortable';
import 'bootstrap-fileinput/js/fileinput';
import 'bootstrap-fileinput/themes/explorer/theme';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = ['name'];

  connect() {
    const input = this.nameTarget;
    this.initFileInput(input);
  }

  initFileInput(input) {
    $(input).fileinput({
      browseClass: 'btn btn-primary',
      browseLabel: 'Browse',
      removeClass: 'btn btn-danger',
      removeLabel: 'Delete',
      allowedFileExtensions: [
        'pdf',
        'png',
        'jpg',
        'jpeg',
        'docx',
        'doc',
        'mp3',
        'wav',
        'm4a',
      ],
      maxFileCount: 5,
      maxFilePreviewSize: 30720,
      maxFileSize: 30720,
      showUpload: false,
      showCancel: false,
      allowedPreviewTypes: ['image'],
    });
  }
}
