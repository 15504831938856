import { Controller } from '@hotwired/stimulus';
import { navigateTo } from '../utils/generalUtils';

export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = [
    'credittype',
    'termname',
    'closingdate',
    'creditdays',
    'formfeedback',
    'loader',
    'partner',
    'makeprimary',
  ];

  connect() {
    this.element.dataset.stimulusStatus = 'connected';
    this.element.addEventListener('submit', e => {
      e.preventDefault();

      this.loaderTarget.classList.add('loader', 'loader-default', 'is-active');

      const data = new FormData();
      data.append('partner', this.partnerTarget.dataset.partnerId);
      data.append('name', this.termnameTarget.value);
      data.append('closing_date', this.closingdateTarget.value);
      data.append('credit_days', this.creditdaysTarget.value);
      data.append('type', this.credittypeTarget.value);
      data.append('default_payment_term', this.makeprimaryTarget.checked);

      this.postData(data).then(response => {
        if (response) {
          if (response.status == 201) {
            this.displaySuccess(response.data);
          } else {
            this.handleFormErrors(response.data);
          }
        }
      });
    });
  }

  async postData(data) {
    try {
      const url = '/partners/add_payment_term';
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {
          'X-CSRFToken': this.element.dataset.csrf,
        },
      });

      const response_data = await response.json();
      const status = response.status;

      return { data: response_data, status: status };
    } catch (error) {
      console.error(error);
    }
  }

  displaySuccess(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-danger');
    this.formfeedbackTarget.classList.add('alert-success');
    this.formfeedbackTarget.innerHTML =
      `<span class="d-block">Payment term has been created.</span>` +
      `<span class="d-block">Refreshing automatically...</span>`;
    let timeout = 1000; // 1 seconds
    navigateTo(data.url, timeout);
  }

  handleFormErrors(data) {
    this.displayGlobalError(data);
  }

  displayGlobalError(data) {
    this.loaderTarget.classList.remove('loader', 'loader-default', 'is-active');

    this.formfeedbackTarget.removeAttribute('hidden');
    this.formfeedbackTarget.classList.remove('alert-success');
    this.formfeedbackTarget.classList.add('alert-danger');
    this.formfeedbackTarget.innerHTML = JSON.stringify(data);
  }
}
