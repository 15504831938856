/**
 * Performs a Geo IP lookup to determine the user's country based on their IP address.
 *
 * This function attempts to fetch the user's country code using the ipapi.co API. If the fetch
 * operation is successful, it calls the provided callback function with the country code
 * (converted to lowercase). If the fetch operation fails (e.g., due to network issues),
 * it calls the callback with a default country code ('ke').
 *
 * @param {Function} callback - A callback function that is called with the country code.
 *  The country code is passed as a string argument to the callback.
 *  The country code is in lowercase, or 'ke' if the fetch fails.
 */
export function geoIpLookup(callback) {
  fetch('https://ipapi.co/json')
    .then(res => res.json())
    .then(data => callback(data.country_code))
    .catch(() => callback('KE'));
}
